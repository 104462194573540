import { ClubConfig, Logos } from '~/config/clubConfig/types'
import { FestivalCodesEnum } from '~/generated/graphql'
import { FestivalSalesOffer } from '~/types/festival'

import { assets } from './assets'
import { copy } from './copy'
import { paths } from './paths'

const logos: Logos = {
  [FestivalCodesEnum.Rw]: {
    centered: '/assets/logo/logo-club.svg',
    left: '/assets/logo/logo-club.svg',
  },
  [FestivalCodesEnum.Bw]: {
    centered: '/assets/logo/logo-festival-bw.svg',
    left: '/assets/logo/logo-festival-bw.svg',
  },
  [FestivalCodesEnum.Fdw]: {
    centered: '/assets/logo/logo-festival-fdw.svg',
    left: '/assets/logo/logo-festival-fdw.svg',
  },
  club: {
    centered: '/assets/logo/logo-club.svg',
    left: '/assets/logo/logo-club.svg',
  },
}

export const config: ClubConfig = {
  aboutPageEnabled: false,
  assets,
  blikEnabled: false,
  blogEnabled: false,
  careerPageEnabled: false,
  chmEnabled: false,
  copy,
  paths,
  customRwSuccessCopy: false,
  d2dEnabled: false,
  defaultGeolocation: {
    latitude: 52.5204015,
    longitude: 13.4038786,
  },
  defaultRegion: {
    id: '1',
    name: 'Berlin',
  },
  directCardPaymentEnabled: false,
  enabledLanguages: {
    en: 'English',
    de: 'German',
  },
  extraMarketingConsentEnabled: false,
  festivalSalesOffer: FestivalSalesOffer.WR,
  footerAwardsEnabled: false,
  klarnaEnabled: false,
  logos: logos,
  otherFestivalsEnabled: false,
  paypoEnabled: false,
  redirectHeroToLandingEnabled: false,
  restaurantsListingEnabled: false,
  showFooterCopyright: false,
  sideEventListingPageEnabled: false,
  useRWInsteadOfRC: true,
  visaMobileEnabled: false,
  hideRCInfo: false,
  currencyFormat: 'de-DE',
  supportEmail: 'info@restaurant-week.de',
  redirectRestaurantPageToListing: false,
  tin: {
    placeholder: '000000000',
    minLength: 9,
    maxLength: 9,
  },
  showReservationCancelledDescription: false,
}

export default config
