import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Body1, Heading6 } from '~/theme/utils/typography'

import { BasicIcon } from '~/shared/atoms/Icon'

export const MenuButtonLabel = styled(Heading6)`
  cursor: pointer;
  font-weight: 500;
`

export const Separator = styled.hr`
  width: 90%;
`

export const UserMenuContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  align-self: start;
  width: ${({ width }) => (width === 'full' ? '100%' : '18rem')};
  padding: ${({ width }) => (width === 'full' ? '1.25rem 0.5rem 2rem 0.5rem' : '1.5rem 1rem')};
  border-radius: 0.75rem;
  box-shadow: ${({ theme, width }) => (width === 'full' ? 'none' : theme.commons.shadow.l)};
  background: ${({ theme }) => `${theme.colors.gray[0]}`};

  ${MenuButtonLabel} {
    color: ${({ theme, width }) => theme.colors.gray[width === 'full' ? 1000 : 700]};
  }

  ${Separator} {
    border-top: ${({ theme, width }) => `1px solid ${theme.colors.gray[width === 'full' ? 100 : 200]}`};
  }
`

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  padding: 0 1rem;
  margin-bottom: 0.625rem;
`

export const UsernameLabel = styled(Heading6)`
  font-weight: 600;
  color: ${({ theme }) => `${theme.colors.gray[1000]}`};
`

export const MailLabel = styled(Body1)`
  font-weight: 500;
  color: ${({ theme }) => `${theme.colors.gray[700]}`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const SignOutButtonContainer = styled(ButtonsContainer)``

export const MenuButtonIcon = styled(BasicIcon)`
  height: 1.5rem;
  width: 1.5rem;
  margin: 0 0.75rem;
  filter: ${({ theme }) => theme.filters.gray[700]};
`

export const MenuButtonContainer = styled.a<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 2.5rem;
  border-radius: 0.5rem;

  &:hover {
    background-color: ${({ theme }) => `${theme.colors.orange[100]}`};

    ${MenuButtonIcon} {
      filter: ${({ theme }) => theme.filters.orange[500]};
    }

    ${MenuButtonLabel} {
      color: ${({ theme }) => theme.colors.gray[1000]};
    }
  }

  ${mediaQueryUp('m')} {
    background-color: ${({ active, theme }) => (active ? theme.colors.orange[100] : 'none')};
  }
`
