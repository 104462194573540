import { FestivalCodesEnum } from '~/generated/graphql'
import { gt as GT } from '~/locale'

import { ClubConfigCopy } from '../../types'

import { earlyReservation, voucherTranslation } from './pageTranslate'

const rwReservationStatusMessages = (gt: typeof GT) => ({
  PENDING: gt.tp('OrderStatus', 'Processing your reservation...'),
  SUCCEEDED: gt.tp(
    'OrderStatus',
    'Booking confirmed!\nYour codes* for subsequent reservations:\n-35 PLN for 5 or more Guests: RW5GOSCI\n-15 PLN for 2 or more Guests: REPEAT2J'
  ),
  FAILED: gt.tp('OrderStatus', 'Payment rejected'),
  CANCELED: gt.tp('OrderStatus', 'Reservation cancelled'),
})

const giftStatusMessages = (gt: typeof GT) => ({
  PENDING: gt.tp('OrderStatus', 'Processing your purchase...'),
  SUCCEEDED: gt.tp('OrderStatus', 'Congrats! Your gift purchase is complete!'),
  FAILED: gt.tp('OrderStatus', 'Payment rejected'),
  CANCELED: gt.tp('OrderStatus', 'Gift purchase cancelled'),
})

const beforeYouVisitTitle = (gt: typeof GT) => gt.tp('SuccessPage', 'Read before you visit')

const beforeYouVisitDefaultDescription = (gt: typeof GT) =>
  gt.tp(
    'SuccessPage',
    // eslint-disable-next-line max-len
    'Be eco! Don\'t print the confirmation - at the restaurant, the confirmation on your phone will be enough. Please be on time :). Punctuality gives everyone an equal opportunity to enjoy their dream restaurant experience. After the visit, log in to your account, select "Your reservations" and "Review". The restaurant counts on your honest opinion!'
  )

export const marketingConsent = (gt: typeof GT) => ({
  footer: {
    label: gt.tp(
      'Footer',
      'By pressing „Join” you accept collecting and benefiting from DISCOUNT CODES, be notified of Festivals, obtain eBook “RESTAURANTS’ RECIPES” (price PLN 78), therefore I consent to the sending of the commercial information'
    ),
    expandedText: gt.tp(
      'Footer',
      'to me by RestaurantClub sp. z o. o. to the contact details I provided and the use of these data for direct marketing purposes via electronic terminal devices (e-mail, online social networking sites, phone number provided by me - SMS, telephone contact).'
    ),
  },
  form: {
    label: gt.tp(
      'RegisterForm',
      '{{ thankYouBold }} - I want to use DISCOUNT CODES, receive notifications about Festivals, and get the "RESTAURANT RECIPES" eBook {{ valuedAtBold }}. Therefore, I consent to receiving commercial information',
      {
        thankYouBold: `<b>${gt.tp('RegisterForm', 'Yes, thank you')}</b>`,
        valuedAtBold: `<b>${gt.tp('RegisterForm', '(valued at PLN 78)')}</b>`,
      }
    ),
    expandedText: gt.tp(
      'RegisterForm',
      'to me by RestaurantClub sp. z o. o. to the contact details I provided and the use of these data for direct marketing purposes via electronic terminal devices (e-mail, online social networking sites, phone number provided by me - SMS, telephone contact).'
    ),
  },
})

export const getMetadata = (gt: typeof GT) => ({
  default: {
    title: gt.tp('Head', 'No1 ONLINE BOOKING AND FESTIVALS | RestaurantClub'),
    description: gt.tp(
      'Head',
      "Book top restaurants at a special price. Discover the show-stopping menus of Chefs and the world's most popular Restaurant Festival - RestaurantWeek®."
    ),
    imageUrl: 'https://restaurantclub.pl/assets/rc_share.png',
    imageAlt: 'rc_share',
    siteName: gt.tp('Head', 'RestaurantClub.pl'),
    url: 'https://restaurantclub.pl',
  },
  festivals: {
    [FestivalCodesEnum.Rw]: {
      title: gt.tp('ListingPage', 'RestaurantWeek® | Top ticketed festival in Poland'),
      description: gt.tp(
        'ListingPage',
        'Flagship 3 courses + cocktail from 69,99* at top restaurants! Join nearly 2 million Guests and discover restaurants at their best (*+fee 7,98 PLN).'
      ),
      imageUrl: 'https://restaurantclub.pl/assets/rwp_share.png',
      imageAlt: 'rwp_share',
    },
    [FestivalCodesEnum.Fdw]: {
      title: gt.tp('ListingPage', 'FineDiningWeek® | Festival of Restaurant Art'),
      description: gt.tp(
        'ListingPage',
        'A masterful 3/5 dishes + cocktail at a festival price - in the most outstanding (including Michelin-starred) restaurants in Poland! Discover Polish fine dining!'
      ),
      imageUrl: 'https://restaurantclub.pl/assets/rc_share.png',
      imageAlt: 'rc_share',
    },
    [FestivalCodesEnum.Bw]: {
      title: gt.tp('ListingPage', 'BreakfastWeek | Trendy breakfasts in the city!'),
      description: gt.tp(
        'ListingPage',
        'Signature breakfasts + coffee as a gift in the trendiest places. Discover, book and skip the queues in your neighbourhood and across the city!'
      ),
      imageUrl: 'https://restaurantclub.pl/assets/bw_share.png',
      imageAlt: 'bw_share',
    },
  },
})

export const copy: ClubConfigCopy = {
  voucher: voucherTranslation,
  rwReservationStatusMessages,
  giftStatusMessages,
  beforeYouVisitTitle,
  beforeYouVisitDefaultDescription,
  marketingConsent,
  metadata: getMetadata,
  earlyReservation,
  footerInfo: {},
}
