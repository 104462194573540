import styled from '@emotion/styled'

import { mediaQueryUp } from '~/utils/grid'

export const AssistanceDialogContentWrapper = styled.div`
  background: ${({ theme }) => theme.colors.gray[0]};
  box-shadow: ${({ theme }) => theme.commons.shadow.l};
  padding: 40px;
  border-radius: 1rem;
  width: 100%;
  display: grid;
  gap: 24px;

  height: 100%;
  grid-auto-rows: max-content;

  ${mediaQueryUp('m')} {
    width: 550px;

    height: initial;
  }
`

export const AssistanceDialogActionWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
  flex-direction: column;

  ${mediaQueryUp('m')} {
    flex-direction: row;
  }
`

export const AssistanceDialogTitle = styled.p`
  ${({ theme }) => theme.text.h4({ theme, fontWeight: 'semiBold' })};

  ${mediaQueryUp('m')} {
    ${({ theme }) => theme.text.h3({ theme, fontWeight: 'semiBold' })};
  }
`

export const AssistanceDialogContent = styled.p`
  ${({ theme }) => theme.text.h6({ theme })};

  & span {
    font-weight: 600;
  }
`
