import { useEffect } from 'react'

import { useRouter } from 'next/router'

import { Options } from '@growthbook/growthbook'
import { GrowthBook, useFeatureIsOn as _useFeatureIsOn } from '@growthbook/growthbook-react'

import { processEnv } from '~/utils/env'
import useUser from '~/utils/hooks/useUser'

import config from '~/config'

import { getGbUserId } from './growthbook-lib'

export type Trackers = 'gtag' | 'gtm' | 'segment'

export const initGrowthbookInstance = (growthbook: GrowthBook) => {
  if (!growthbook.getClientKey()) {
    // eslint-disable-next-line no-console
    console.warn('Missing GrowthBook clientKey')
    return
  }

  return growthbook.init()
}

export const growthbookConfig: Options = {
  apiHost: processEnv.GROWTHBOOK_API_HOST,
  clientKey: processEnv.GROWTHBOOK_CLIENT_KEY,
  decryptionKey: processEnv.GROWTHBOOK_DECRYPTION_KEY,
  enableDevMode: processEnv.NODE_ENV !== 'production',
  trackingCallback: async (experiment, result) => {
    const promises: Promise<unknown>[] = []
    const eventParams = { experiment_id: experiment.key, variation_id: result.key }

    // GTM - dataLayer
    if (window.dataLayer) {
      let datalayerResolve
      const datalayerPromise = new Promise(resolve => {
        datalayerResolve = resolve
      })
      promises.push(datalayerPromise)
      window.dataLayer.push({
        event: 'experiment_viewed',
        ...eventParams,
        eventCallback: datalayerResolve,
      })
    }

    await Promise.all(promises)
  },
}

interface AppFeatures {
  'automatic-payment-method-discount-application': boolean
  'fer-listing-map': boolean
  'checkout-button-always-active': boolean
  'd2d-listing-map': boolean
}

export const useGrowthbookDataRefresh = (growthbook: GrowthBook) => {
  const { user } = useUser()
  const router = useRouter()

  useEffect(() => {
    if (!growthbook || !growthbook.getClientKey()) return
    growthbook.setURL(window.location.href)
    growthbook.setAttributes({
      id: getGbUserId(),
      loggedIn: !!user,
      email: user?.email,
      browser: navigator.userAgent,
      url: router.pathname,
      market: config().marketCode,
    })
  }, [growthbook, router.pathname, user])
}

export const useFeatureIsOn = (id: keyof AppFeatures & string): boolean => _useFeatureIsOn<AppFeatures>(id)
