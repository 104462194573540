import { Formik } from 'formik'

import { ApolloError } from '@apollo/client'

import { Heading2, Heading5 } from '~/theme/utils/typography'
import { formatErrorMessages } from '~/utils/formatErrorMessages'

import { paths } from '~/constants'
import { CreateUserMutationVariables, useCreateUserMutation, UserDataDocument, useUserDataQuery } from '~/generated/graphql'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import Checkbox from '~/shared/atoms/Checkbox'
import Input from '~/shared/atoms/Input'
import PhoneInput from '~/shared/atoms/PhoneInput'
import { useToast } from '~/shared/atoms/Toast'
import { bigModalPropsTemplate, smallModalPropsTemplate, useModal } from '~/shared/molecules/Modal'
import { SignInCallback } from '~/types/signIn'

import { AppleLoginButton } from '../AppleLogin/AppleLoginButton'
import FacebookLoginButton from '../FacebookLoginButton'
import GoogleLoginButton from '../GoogleLoginButton'
import LoginModal from '../LoginModal'
import { OtherLogin, OtherLoginButtons, StyledToastContainer } from '../LoginModal/LoginModalShards'
import VerifyAccountModal from '../VerifyAccountModal'

import { ConsentNotice, StyledAnchor, StyledCheckboxWrapper, StyledForm, StyledInputs, StyledLink } from './RegisterModalShards'
import { registerSchema } from './RegisterModalUtils'

type RegisterFormProps = {
  prefilledData?: Partial<CreateUserMutationVariables>
  mainHeading?: string
  onRegister?: SignInCallback
  handleClose?: () => void
}

export const RegisterForm = ({ handleClose, mainHeading, onRegister, prefilledData }: RegisterFormProps) => {
  const { refetch } = useUserDataQuery()
  const [createUser] = useCreateUserMutation()
  const modal = useModal()
  const toast = useToast()

  const handleErrors = (error: ApolloError) => {
    const errors = formatErrorMessages(error)
    errors?.map(error =>
      toast.add({
        message: error,
        type: 'error',
        containerSelector: '#RegisterToastContainer',
        size: 'small',
      })
    )
  }

  const onSocialSignUp: SignInCallback = async user => {
    await refetch()
    if (user) onRegister?.(user)
    handleClose && handleClose()
  }

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        marketingConsentAccepted: false,
        regulationConsentAccepted: false,
        ...prefilledData,
      }}
      validationSchema={registerSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await createUser({
          variables: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            phoneNumber: values.phoneNumber,
            marketingConsentAccepted: values.marketingConsentAccepted,
          },
          onCompleted: data => {
            modal?.open(
              smallModalPropsTemplate,
              <VerifyAccountModal
                handleClose={() => {
                  modal.close()
                  handleClose && handleClose()
                }}
              />
            )
            onRegister?.(data?.createUser.user ?? null)
          },
          refetchQueries: [{ query: UserDataDocument }, 'UserData'],
          onError: error => handleErrors(error),
        })
        setSubmitting(false)
      }}>
      {({ errors, touched, values, handleSubmit, isSubmitting, isValid, handleChange, handleBlur }) => (
        <StyledForm>
          <Heading2 fontWeight='medium'>{mainHeading}</Heading2>
          <Heading5 fontWeight='medium'>
            {gt.tp('RegisterForm', 'Already have an account?')}{' '}
            <StyledLink onClick={() => modal?.open(bigModalPropsTemplate, <LoginModal handleClose={modal.close} />)}>
              {gt.tp('RegisterForm', 'Log in here')}
            </StyledLink>
          </Heading5>
          <StyledToastContainer id={'RegisterToastContainer'} />
          <StyledInputs>
            <Input
              title={gt.tp('RegisterForm', 'First name*')}
              name='firstName'
              actionsRight={[]}
              error={touched.firstName && !!errors.firstName}
              errorMessage={touched.firstName ? gt.tp('Error', errors.firstName as string) : ''}
              height={'big'}
              width={'full'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
            />
            <Input
              title={gt.tp('RegisterForm', 'Last name*')}
              name='lastName'
              actionsRight={[]}
              error={touched.lastName && !!errors.lastName}
              errorMessage={touched.lastName ? gt.tp('Error', errors.lastName as string) : ''}
              height={'big'}
              width={'full'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
            />
            <Input
              title={gt.tp('RegisterForm', 'Email*')}
              name='email'
              actionsRight={[]}
              type='email'
              error={touched.email && !!errors.email}
              errorMessage={touched.email ? gt.tp('Error', errors.email as string) : ''}
              height={'big'}
              width={'full'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <PhoneInput
              name='phoneNumber'
              defaultValue={values.phoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.phoneNumber && !!errors.phoneNumber}
              errorMessage={touched.phoneNumber && !!errors.phoneNumber ? gt.tp('Error', errors.phoneNumber) : undefined}
              placeholder='690 729 222'
              title={gt.tp('RegisterForm', 'Phone')}
            />
            <Input
              title={gt.tp('RegisterForm', 'Password*')}
              name='password'
              actionsRight={[]}
              type='password'
              error={touched.password && !!errors.password}
              errorMessage={touched.password ? gt.tp('Error', errors.password as string) : ''}
              height={'big'}
              width={'full'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password || ''}
            />
          </StyledInputs>
          <StyledCheckboxWrapper>
            <Checkbox
              name='marketingConsentAccepted'
              onChange={handleChange}
              onBlur={handleBlur}
              checked={!!values.marketingConsentAccepted}
              label={
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${gt.tp(
                      'RegisterForm',
                      '{{ thankYouBold }} - I want to use DISCOUNT CODES, receive notifications about Festivals, and get the "RESTAURANT RECIPES" eBook {{ valuedAtBold }}. Therefore, I consent to receiving commercial information',
                      {
                        thankYouBold: `<b>${gt.tp('RegisterForm', 'Yes, thank you')}</b>`,
                        valuedAtBold: `<b>${gt.tp('RegisterForm', '(valued at PLN 78)')}</b>`,
                      }
                    )}`,
                  }}></span>
              }
              showMore={gt.tp(
                'RegisterForm',
                'from RestaurantClub sp. z o.o. to the contact details I provided and to the use of these details for direct marketing purposes via electronic communication devices (email, social media platforms, SMS to the provided phone number, and phone contact).'
              )}
            />
          </StyledCheckboxWrapper>
          <StyledCheckboxWrapper>
            <Checkbox
              name='regulationConsentAccepted'
              label={
                <>
                  {gt.tp('CheckoutPage', '* I accept the')}{' '}
                  <StyledAnchor href={paths.regulations} target='_blank'>
                    {gt.tp('CheckoutPage', 'Terms and Conditions')}
                  </StyledAnchor>{' '}
                  {gt.tp('CheckoutPage', 'and have read the')}{' '}
                  <StyledAnchor href={paths.privacyPolicy} target='_blank'>
                    {gt.tp('CheckoutPage', 'Privacy Policy')}.
                  </StyledAnchor>
                </>
              }
              checked={!!values.regulationConsentAccepted}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.regulationConsentAccepted && !!errors.regulationConsentAccepted}
            />
          </StyledCheckboxWrapper>
          <ConsentNotice>
            {gt.tp(
              'ConsentNotice',
              'If you do not agree to the communication, you will lose the ability to contact you about your bookings. It will also prevent us from communicating booking benefits to you.'
            )}
          </ConsentNotice>
          <Button
            label={gt.tp('RegisterForm', 'Register')}
            height='big'
            width='full'
            color='green'
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
            onClick={() => handleSubmit()}
          />
          <OtherLogin>{gt.tp('LoginForm', 'or')}</OtherLogin>
          <OtherLoginButtons>
            <GoogleLoginButton onLogin={onSocialSignUp} label={gt.tp('Register', 'Sign up with Google')} />
            <FacebookLoginButton onLogin={onSocialSignUp} label={gt.tp('Register', 'Sign up with Facebook')} />
            <AppleLoginButton onLogin={onSocialSignUp} label={gt.tp('Register', 'Sign up with Apple')} />
          </OtherLoginButtons>
        </StyledForm>
      )}
    </Formik>
  )
}
