import styled from '@emotion/styled'

export const DialogBackdrop = styled.div`
  background: rgb(0 0 0 / 50%);
  position: fixed;
  inset: 0;
  z-index: 1001;
`
export const DialogPositioner = styled.div<{ center: boolean }>`
  position: fixed;
  inset: 0;
  z-index: 1001;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ center }) => center && 'align-content: center;'}
`
export const DialogContent = styled.div<{ center: boolean }>`
  pointer-events: auto;
  width: max-content;
  height: max-content;
  margin: 100px auto 0;
  position: relative;
  z-index: 1002;

  ${({ center }) => center && 'align-content: center;'}

  @media (max-width: 600px) {
    margin: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
`
export const DialogCloseButton = styled.button`
  background: #fff;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  margin: 20px 0;
  margin-left: auto;

  position: absolute;
  top: -80px;
  right: 0;
  cursor: pointer;
  font-size: 1rem;

  @media (max-width: 600px) {
    top: 8px;
    right: 8px;
    margin: 0;
    z-index: 1002;
    pointer-events: auto;
  }
`
