import { ClubConfig, Logos } from '~/config/clubConfig/types'
import { FestivalCodesEnum } from '~/generated/graphql'
import { FestivalSalesOffer } from '~/types/festival'

import { assets } from './assets'
import { copy } from './copy'
import { paths } from './paths'

const logos: Logos = {
  [FestivalCodesEnum.Rw]: {
    centered: '/assets/logo/logo-festival-rwc-c.svg',
    left: '/assets/logo/logo-festival-rwc-l.svg',
  },
  [FestivalCodesEnum.Bw]: {
    centered: '/assets/logo/logo-festival-bw.svg',
    left: '/assets/logo/logo-festival-bw.svg',
  },
  [FestivalCodesEnum.Fdw]: {
    centered: '/assets/logo/logo-festival-fdw.svg',
    left: '/assets/logo/logo-festival-fdw.svg',
  },
  club: {
    centered: '/assets/logo/logo-festival-rwc-c.svg',
    left: '/assets/logo/logo-festival-rwc-l.svg',
  },
}

export const config: ClubConfig = {
  aboutPageEnabled: false,
  assets,
  blikEnabled: false,
  blogEnabled: false,
  careerPageEnabled: false,
  chmEnabled: false,
  copy,
  paths,
  customRwSuccessCopy: false,
  d2dEnabled: false,
  defaultGeolocation: {
    latitude: 50.083218,
    longitude: 14.421597,
  },
  defaultRegion: {
    id: '1',
    name: 'Praha',
  },
  directCardPaymentEnabled: false,
  enabledLanguages: {
    en: 'English',
    cs: 'Czech',
  },
  extraMarketingConsentEnabled: false,
  festivalSalesOffer: FestivalSalesOffer.WR,
  footerAwardsEnabled: false,
  klarnaEnabled: false,
  logos: logos,
  otherFestivalsEnabled: false,
  paypoEnabled: false,
  redirectHeroToLandingEnabled: true,
  restaurantsListingEnabled: false,
  showFooterCopyright: false,
  sideEventListingPageEnabled: false,
  useRWInsteadOfRC: true,
  visaMobileEnabled: false,
  hideRCInfo: true,
  currencyFormat: 'cs-CZ',
  supportEmail: 'info@restaurant-week.cz',
  redirectRestaurantPageToListing: FestivalCodesEnum.Rw,
  tin: {
    placeholder: '00000000',
    minLength: 8,
    maxLength: 10,
  },
  showReservationCancelledDescription: true,
}

export default config
