import { validationFields } from '~/utils/validationFields'
import yup from '~/utils/validationMessages'

import { ClubConfig, getClubConfig } from '~/config/clubConfig'
import { paths } from '~/constants'
import { FestivalCodesEnum } from '~/generated/graphql'

export const joinTheClubSchema = yup.object().shape({
  email: validationFields.email,
})

type FooterLink = {
  label: string
  href?: string
  enabledForFeatureFlag?: {
    key: keyof ClubConfig
    value: boolean
  }
  onClick?: () => void
}

type LinkData = {
  title: string | ((hideRCInfo: boolean) => string)
  links: FooterLink[]
  disabledForFeatureFlag?: keyof ClubConfig
}

export const getFooterLinkData = (festivalCode?: string): Array<LinkData> => {
  if (getClubConfig().hideRCInfo) {
    return linkData
  }
  switch (festivalCode) {
    case FestivalCodesEnum.Rw:
      return rwLinkData
    case FestivalCodesEnum.Bw:
      return bwLinkData
    default:
      return linkData
  }
}

export const bwLinkData: Array<LinkData> = [
  {
    title: 'BreakfastWeek',
    links: [
      {
        label: 'Festival restaurants',
        href: paths.festivalListing('breakfast-week'),
      },
      {
        label: 'RestaurantWeek®',
        href: paths.festivalLanding('restaurant-week'),
      },
      {
        label: 'For a gift',
        href: paths.giftEventsListing,
      },
      {
        label: 'Premium Experiences',
        href: paths.sideEventsListing,
      },
    ],
  },
  {
    title: 'RestaurantClub Sp. z o.o.',
    links: [
      { label: 'Career', href: paths.career, enabledForFeatureFlag: { key: 'careerPageEnabled', value: true } },
      { label: 'About us', href: paths.about, enabledForFeatureFlag: { key: 'aboutPageEnabled', value: true } },
      { label: 'Weekly', href: paths.blog },
      { label: 'Newsletter', href: paths.newsletter },
      { label: 'Your profile', href: paths.userInformation },
    ],
  },
  {
    title: 'Information',
    links: [
      { label: 'FAQ', href: paths.faq },
      { label: 'Privacy Policy', href: paths.privacyPolicy },
      { label: 'Terms', href: paths.regulations },
      { label: 'Contact', href: paths.faq },
    ],
  },
]

export const rwLinkData: Array<LinkData> = [
  {
    title: 'RestaurantWeek®',
    links: [
      {
        label: 'Festival restaurants',
        href: paths.festivalListing('restaurant-week'),
      },
      {
        label: 'BreakfastWeek',
        href: paths.festivalLanding('breakfast-week'),
      },
      {
        label: 'For a gift',
        href: paths.giftEventsListing,
      },
      {
        label: 'Premium Experiences',
        href: paths.sideEventsListing,
      },
    ],
  },
  {
    title: 'RestaurantClub Sp. z o.o.',
    links: [
      { label: 'Career', href: paths.career, enabledForFeatureFlag: { key: 'careerPageEnabled', value: true } },
      { label: 'About us', href: paths.about, enabledForFeatureFlag: { key: 'aboutPageEnabled', value: true } },
      { label: 'Weekly', href: paths.blog },
      { label: 'Newsletter', href: paths.newsletter },
      { label: 'Your profile', href: paths.userInformation },
    ],
  },
  {
    title: 'Information',
    links: [
      { label: 'FAQ', href: paths.faq },
      { label: 'Privacy Policy', href: paths.privacyPolicy },
      { label: 'Terms', href: paths.regulations },
      { label: 'Contact', href: paths.faq },
    ],
  },
]

const linkData: Array<LinkData> = [
  {
    title: (hideRCInfo: boolean) => (hideRCInfo ? 'Festival' : 'Restaurant Club'),
    links: [
      { label: 'Festivals', href: paths.festivals, enabledForFeatureFlag: { key: 'hideRCInfo', value: false } },
      {
        label: 'Festival restaurants',
        href: paths.festivalListing('restaurant-week'),
        enabledForFeatureFlag: { key: 'hideRCInfo', value: true },
      },
      { label: 'About us', href: paths.about, enabledForFeatureFlag: { key: 'aboutPageEnabled', value: true } },
      { label: 'Partners', href: paths.partners },
      { label: 'Newsletter', href: paths.newsletter },
      { label: 'Your profile', href: paths.userInformation, enabledForFeatureFlag: { key: 'hideRCInfo', value: false } },
      { label: 'Book a table', href: paths.restaurants, enabledForFeatureFlag: { key: 'd2dEnabled', value: true } },
      { label: '#ChefsMenu', href: paths.chefsMenuOffers, enabledForFeatureFlag: { key: 'chmEnabled', value: true } },
    ],
  },
  {
    title: 'Company',
    links: [
      // { label: 'For restaurants', href: paths.forRestaurateurs },
      { label: 'Help', href: paths.faq },
      { label: 'Career', href: paths.career, enabledForFeatureFlag: { key: 'careerPageEnabled', value: true } },
      { label: 'Privacy Policy', href: paths.privacyPolicy, enabledForFeatureFlag: { key: 'hideRCInfo', value: true } },
      { label: 'Terms', href: paths.regulations, enabledForFeatureFlag: { key: 'hideRCInfo', value: true } },
      { label: 'Contact', href: paths.faq, enabledForFeatureFlag: { key: 'hideRCInfo', value: true } },
      // { label: 'Become our Partner!', href: paths.partners },
    ],
  },
  {
    title: 'Information',
    links: [
      { label: 'Privacy Policy', href: paths.privacyPolicy },
      { label: 'Terms', href: paths.regulations },
      { label: 'Contact', href: paths.faq },
      { label: 'Cookies', onClick: () => window.OneTrust.ToggleInfoDisplay() },
    ],
    disabledForFeatureFlag: 'hideRCInfo',
  },
]

export const benefitsData = [
  'Discounts - the ability to use them',
  'Festivals - information when festivals start',
  'Gifts - from Festival Partners',
  'Contests - prizes to be won',
]
