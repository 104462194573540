// https://support.thulium.com/pl/show/277 api

export const openChatBot = () => {
  const chatbotContainer = document
    ?.querySelector<HTMLIFrameElement>('.thulium-chat-frame-wrapper')
    ?.contentWindow?.document.querySelector('.tc-desktop-chat')

  if (!chatbotContainer?.classList.contains('tc-view-open')) {
    window._tc('open_chat_form')
  }
}
