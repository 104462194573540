import { ReactNode, useId } from 'react'

import { SetOptional } from 'type-fest'

import * as dialog from '@zag-js/dialog'
import { normalizeProps, Portal, useMachine } from '@zag-js/react'

import { DialogBackdrop, DialogCloseButton, DialogContent, DialogPositioner } from './DialogShards'

export interface DialogProps {
  dialogContext: SetOptional<dialog.Context, 'id'>
  children: ReactNode
  center?: boolean
}

export const DialogView = ({ dialogContext = {}, children, center = false }: Partial<DialogProps>) => {
  const id = useId()

  const [state, send] = useMachine(
    dialog.machine({
      id: dialogContext.id || id,
      open: dialogContext.open,
    }),
    {
      context: dialogContext,
    }
  )

  const api = dialog.connect(state, send, normalizeProps)

  return (
    <>
      {api.open && (
        <Portal>
          <DialogBackdrop {...api.getBackdropProps()} />
          <DialogPositioner {...api.getPositionerProps()} center={center}>
            <DialogContent {...api.getContentProps()} center={center}>
              <DialogCloseButton className='dialog' {...api.getCloseTriggerProps()}>
                X
              </DialogCloseButton>
              {children}
            </DialogContent>
          </DialogPositioner>
        </Portal>
      )}
    </>
  )
}
