import React from 'react'

import { openChatBot } from '~/utils/chatBot'

import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'

import { Dialog } from '../Dialog'

import { AssistanceDialogActionWrapper, AssistanceDialogContentWrapper, AssistanceDialogTitle } from './AssistanceShards'

export function AssistanceDialog({
  onClose,
  open,
  title,
  children,
}: {
  open: boolean
  onClose: () => void
  title: string
  children: JSX.Element | JSX.Element[]
}) {
  return (
    <Dialog
      dialogContext={{
        open,
        onOpenChange: onClose,
        'open.controlled': true,
        id: 'assistanceDialog',
      }}
      center>
      <AssistanceDialogContentWrapper>
        <AssistanceDialogTitle> {title}</AssistanceDialogTitle>
        {children}
        <AssistanceDialogActionWrapper>
          <Button
            label={gt.tp('UserMenuAssistance', 'Open chat')}
            variant='outlined'
            color='orange'
            fontWeight='regular'
            onClick={() => {
              openChatBot()
              onClose()
            }}
            width='full'
          />
          <Button label={gt.tp('UserMenuAssistance', 'close')} color='orange' fontWeight='regular' onClick={onClose} width='full' />
        </AssistanceDialogActionWrapper>
      </AssistanceDialogContentWrapper>
    </Dialog>
  )
}
