// @ts-strict-ignore
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { useRouter } from 'next/router'

import { mediaQueryIsTablet } from '~/theme/utils/grid'
import { Separator, UniversalContainer } from '~/utils/grid'
import { getLogos } from '~/utils/helpers'
import useUser from '~/utils/hooks/useUser'

import { SearchHeroModalContent } from '~/components/Home/HomePage'
import ReservationModal from '~/components/Reservation/ReservationModal'
import LoginModal from '~/components/User/LoginModal'
import RegisterModal from '~/components/User/RegisterModal'
import UserMenu from '~/components/User/UserMenu'
import { isDemo } from '~/config/clubConfig'
import { HOME_HERO_SHORTCUTS, INITIAL_NUMBER_OF_ATTENDEES, paths } from '~/constants'
import { FestivalCodesEnum, useFirstUpcomingReservationQuery, useOngoingFestivalsListQuery } from '~/generated/graphql'
import { FestivalListingHeaderTopBar } from '~/layout/FestivalListing/FestivalListingHeaderTopBar'
import { FestivalListingMobileMenu } from '~/layout/FestivalListing/FestivalListingMobileMenu'
import { gt } from '~/locale'
import Icon from '~/shared/atoms/Icon'
import Link from '~/shared/atoms/Link'
import ShowOnTrigger from '~/shared/atoms/ShowOnTrigger'
import { VisibleIfFeatureFlag } from '~/shared/atoms/VisibleIfFeatureFlag'
import {
  Container,
  DesktopNavLinksContainer,
  HeaderLink,
  HeaderLinkWithAnimation,
  Inner,
  Logo,
  Section,
  SignInButton,
  SignUpButton,
  TabletNavLinksContainer,
  TopBar,
  TopBarContainer,
  Wrapper,
} from '~/shared/molecules/Header/HeaderShards'
import {
  areAnyInPath,
  getHeaderMobileMenuTabs,
  getHeaderTabs,
  getHeaderTopbarTabs,
  isLocationSelectorVisibleOnRoute,
  menuModalProps,
  modalProps,
  searchModalProps,
  useHeaderModals,
} from '~/shared/molecules/Header/HeaderUtils'
import MobileHamburgerNav from '~/shared/molecules/Header/MobileHamburgerNav'
import LocationMenu from '~/shared/molecules/LocationMenu'

import { AssistanceDialog, AssistanceDialogContent } from '../AssistanceDialog'

type FestivalListingHeaderProps = {
  festivalCode?: string
  festivalSlug?: string
}

const Header = ({ festivalCode, festivalSlug }: FestivalListingHeaderProps) => {
  const { tp } = gt
  const { user, loading } = useUser({ returnBlankOnAnonymous: true })
  const loggedIn = !loading && !!user
  const { data: firstUpcomingReservationData } = useFirstUpcomingReservationQuery({ skip: !loggedIn })
  const { data: ongoingFestivalsData } = useOngoingFestivalsListQuery()
  const isTablet = useMediaQuery({ query: mediaQueryIsTablet })

  const upcomingReservation = firstUpcomingReservationData?.myReservations.edges?.[0]?.node
  const totalCountUpcomingReservations = firstUpcomingReservationData?.myReservations.totalCount
  const [showReservationModal, setShowReservationModal] = useState(false)
  const router = useRouter()

  const { modal, showLocationModal } = useHeaderModals()

  const shouldShowLocationMenu = !isTablet && isLocationSelectorVisibleOnRoute(router.route)
  const topTabs = getHeaderTopbarTabs({ festivalCode: festivalCode })
  const mobileTabs = getHeaderMobileMenuTabs({
    festivalCode: festivalCode,
    ongoingFestivalsData: ongoingFestivalsData?.ongoingFestivalEditions,
  })
  const headerTabs = getHeaderTabs(ongoingFestivalsData?.ongoingFestivalEditions, festivalCode)
  const logoRedirect = festivalSlug ? paths.festivalLanding(festivalSlug) : paths.home
  const logoSize = (festivalCode: string) => {
    switch (festivalCode) {
      case FestivalCodesEnum.Bw:
        return 'big'
      case FestivalCodesEnum.Rw:
        return 'medium'
      default:
        return 'small'
    }
  }

  const HeaderTab = ({ href, label, matchingUrlFragments, excludedUrlFragments, enabledForFeatureFlag, newTab, isAnchor }) => {
    const isActive = areAnyInPath([...matchingUrlFragments, href], router.asPath) && !areAnyInPath(excludedUrlFragments, router.asPath)

    const commonProps = {
      ...(newTab && { target: '_blank' }),
      isActive,
      children: gt.tp(label[0], label[1]),
    }

    return (
      <VisibleIfFeatureFlag name={enabledForFeatureFlag?.key} hasValue={enabledForFeatureFlag?.value} key={href}>
        {isAnchor ? (
          <HeaderLinkWithAnimation href={href} {...commonProps} />
        ) : (
          <Link href={href}>
            <HeaderLinkWithAnimation {...commonProps} />
          </Link>
        )}
      </VisibleIfFeatureFlag>
    )
  }

  const [openAssistanceDialog, setOpenAssistanceDialog] = useState(false)

  const handleOpenAssistanceDialog = () => setOpenAssistanceDialog(true)

  return (
    <>
      <Wrapper>
        <TopBarContainer>
          <TopBar>
            <FestivalListingHeaderTopBar topTabs={topTabs} onClose={modal.close} />
          </TopBar>
        </TopBarContainer>
        <UniversalContainer>
          <Container>
            <Section>
              <Link href={logoRedirect}>
                <a>
                  <Logo
                    src={getLogos(festivalCode as FestivalCodesEnum).centered}
                    size={logoSize(festivalCode)}
                    alt='RestaurantClub Logo'
                  />
                </a>
              </Link>
              {shouldShowLocationMenu && <LocationMenu showModal={showLocationModal} />}
            </Section>
            <TabletNavLinksContainer gap='1.5rem'>
              {user && (
                <ShowOnTrigger
                  placement='down-left'
                  type='click'
                  trigger={
                    <HeaderLink as='a'>
                      <Icon icon='user-circle' />
                    </HeaderLink>
                  }
                  contentAsTrigger={true}
                  content={<UserMenu user={user} onOpenAssistanceDialog={handleOpenAssistanceDialog} />}
                />
              )}
              <HeaderLink
                as='button'
                onClick={() =>
                  modal.open(
                    menuModalProps,
                    <MobileHamburgerNav
                      user={user}
                      onOpenAssistanceDialog={handleOpenAssistanceDialog}
                      menu={<FestivalListingMobileMenu mobileTabs={mobileTabs} onClose={modal.close} />}
                    />
                  )
                }>
                <Icon icon='hamburger' />
              </HeaderLink>
            </TabletNavLinksContainer>
            {!isTablet && (
              <DesktopNavLinksContainer loggedIn={loggedIn} shouldShowLocationMenu={shouldShowLocationMenu}>
                {headerTabs.map(HeaderTab)}
              </DesktopNavLinksContainer>
            )}
            {!isTablet && !loggedIn && (
              <Inner gap='1rem'>
                <SignInButton onClick={() => modal.open(modalProps, <LoginModal handleClose={modal.close} />)}>
                  {tp('Header', 'Sign in')}
                </SignInButton>
                <SignUpButton
                  height='small'
                  color='orange'
                  label={tp('Header', 'Sign up')}
                  onClick={() =>
                    modal.open(modalProps, <RegisterModal mainHeading={gt.tp('RegisterForm', 'Register')} handleClose={modal.close} />)
                  }
                />
                <HeaderLink
                  as='a'
                  onClick={() =>
                    modal.open(
                      searchModalProps,
                      <SearchHeroModalContent
                        shortcuts={HOME_HERO_SHORTCUTS(isDemo)}
                        search={{
                          defaultPeopleCount: INITIAL_NUMBER_OF_ATTENDEES,
                        }}
                        onClose={modal.close}
                      />
                    )
                  }>
                  <Icon icon='search' />
                </HeaderLink>
              </Inner>
            )}
            {!isTablet && loggedIn && (
              <Inner gap='1.5rem'>
                <ShowOnTrigger
                  placement='down-left'
                  type='programmatic'
                  onClickOutside={() => setShowReservationModal(false)}
                  show={showReservationModal}
                  trigger={
                    totalCountUpcomingReservations && (
                      <HeaderLink onClick={() => setShowReservationModal(!showReservationModal)}>
                        <Icon
                          icon='calendar'
                          badge={{
                            number: totalCountUpcomingReservations,
                          }}
                        />
                      </HeaderLink>
                    )
                  }
                  content={
                    upcomingReservation && (
                      <ReservationModal closeModal={() => setShowReservationModal(false)} reservation={upcomingReservation} />
                    )
                  }
                />
                <ShowOnTrigger
                  placement='down-left'
                  type='click'
                  trigger={
                    <HeaderLink as='a'>
                      <Icon icon='user-circle' />
                    </HeaderLink>
                  }
                  content={<UserMenu user={user} onOpenAssistanceDialog={handleOpenAssistanceDialog} />}
                  contentAsTrigger={true}
                />
                <HeaderLink
                  as='a'
                  onClick={() =>
                    modal.open(
                      searchModalProps,
                      <SearchHeroModalContent
                        shortcuts={HOME_HERO_SHORTCUTS(isDemo)}
                        search={{
                          defaultPeopleCount: INITIAL_NUMBER_OF_ATTENDEES,
                        }}
                        onClose={modal.close}
                      />
                    )
                  }>
                  <Icon icon='search' />
                </HeaderLink>
              </Inner>
            )}
          </Container>
          <Separator />
        </UniversalContainer>
      </Wrapper>
      <AssistanceDialog
        open={openAssistanceDialog}
        onClose={() => setOpenAssistanceDialog(false)}
        title={gt.tp('UserMenuAssistance', 'Do you need help?')}>
        <AssistanceDialogContent>
          {gt.tp('UserMenuAssistance', 'Do you have a question?')}
          <span> {gt.tp('UserMenuAssistance', 'Ask in the chat!')}</span>
        </AssistanceDialogContent>
      </AssistanceDialog>
    </>
  )
}

export default Header
